import { Link } from 'gatsby';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import './MauticForm.scss';

interface MauticFormProps {
    formId: string;
    formName: string;
    isDark: boolean;
    buttonText?: string;
}

interface MauticFormData {
    mauticform: Record<string, string>;
}

const buildFormData = (data: MauticFormData) => {
    const formData = new FormData();

    for (const key in data['mauticform']) {
        const newKey = `mauticform[${key}]`;
        formData.append(newKey, data['mauticform'][key]);
    }
    formData.append('mauticform[messenger]', '1');
    return formData;
};

const FORM_ACTION_URL = 'https://mautic.theaisummer.com/form/submit?formId=';

const MauticForm: React.FC<MauticFormProps> = ({
    formId,
    formName,
    isDark,
    buttonText
}: MauticFormProps) => {
    const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(
        false
    );
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = (data: MauticFormData) => {
        if (data.mauticform.honey != '') {
            return;
        } else {
            delete data.mauticform.honey;
        }

        const formData = buildFormData(data);

        try {
            fetch(`${FORM_ACTION_URL}${formId}`, {
                method: 'post',
                mode: 'no-cors',
                cache: 'no-cache',
                body: formData
            });
            setIsSuccessfullySubmitted(true);
        } catch (error) {
            // handle server errors
            console.log('Request failed', error);
        }
    };

    return isSuccessfullySubmitted ? (
        <div className="mauticform-success">
            <p>Thank you for joining AI Summer</p>
        </div>
    ) : (
        <div
            id={`mauticform_wrapper__${formName}`}
            className="mauticform-wrapper"
        >
            <form
                autoComplete="false"
                role="form"
                method="POST"
                onSubmit={handleSubmit(onSubmit)}
                id={`mauticform_${formName}`}
                data-mautic-form={`${formName}`}
            >
                <div className="mauticform-innerform">
                    <div
                        className="mauticform-page-wrapper"
                        data-mautic-form-page="1"
                    >
                        <div className="mauticform-honey">
                            <input
                                name="mauticform[honey]"
                                className="mauticform-honey--input"
                                type="text"
                                tabIndex={-1}
                                autoComplete="false"
                                ref={register}
                            ></input>
                        </div>
                        <div
                            id={`mauticform_${formName}_email`}
                            data-validate="email"
                            data-validation-type="email"
                            className="mauticform-email"
                        >
                            <input
                                id={`mauticform_input_${formName}_email`}
                                name="mauticform[email]"
                                className="mauticform-email--input"
                                type="email"
                                placeholder="Enter your email here..."
                                ref={register({
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: 'Enter a valid e-mail address'
                                    }
                                })}
                            />
                        </div>

                        <div
                            id={`mauticform_${formName}_join_ai_summer`}
                            className="mauticform-button-wrapper"
                        >
                            <button
                                type="submit"
                                name="mauticform[join_ai_summer]"
                                id={`mauticform_input_${formName}_join_ai_summer`}
                                value=""
                                className="mauticform-button"
                            >
                                {buttonText || 'Subscribe'}
                            </button>
                        </div>
                    </div>
                    {errors.mauticform && errors.mauticform.email && (
                        <span className="mauticform-errormsg">
                            {errors.mauticform.email.message}
                        </span>
                    )}
                    <div
                        className="mauticform-error"
                        id={`mauticform_${formName}_error`}
                    ></div>
                    <div
                        className="mauticform-message"
                        id={`mauticform_${formName}_message`}
                    ></div>
                </div>

                <input
                    type="hidden"
                    name="mauticform[formId]"
                    id={`mauticform_${formName}_id`}
                    value={formId}
                    ref={register}
                />
                {/* TODO: make value the proper url( current page) */}
                <input
                    type="hidden"
                    name="mauticform[return]"
                    id={`mauticform_${formName}_return`}
                    value="https://theaisummer.com"
                    ref={register}
                />
                <input
                    type="hidden"
                    name="mauticform[formName]"
                    id={`mauticform_${formName}_name`}
                    value={formName}
                    ref={register}
                />
            </form>
            <div className={`form-privacy-text--${isDark ? 'light' : 'dark'}`}>
                <p>
                    * We're committed to your privacy. AI Summer uses the
                    information you provide to send you our newsletter
                    and contact you about our products. You may unsubscribe from
                    these communications at any time. For more information,
                    check out our{' '}
                    <Link to="/privacy_policy/">Privacy Policy</Link>.
                </p>
            </div>
        </div>
    );
};

export default MauticForm;
