import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { Accent, ButtonType } from '../../../../../globals';
import Button from '../../../Button/Button';
import './InlineDLProdBookBanner.scss';

const fetchDLProdCoverQuery = graphql`
    query {
        file(relativePath: { eq: "deep-learning-book-cover.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;

const InlineDLProdBookBanner: React.FC = () => {
    const image = useStaticQuery(fetchDLProdCoverQuery);
    return (
        <div className="dl-prod-book-inline-banner">
            <Img
                fluid={image.file.childImageSharp.fluid}
                alt="Deep Learning in Production Book"
                className="dl-prod-book-inline-banner__image"
                imgStyle={{ objectFit: 'contain' }}
            ></Img>
            <div className="dl-prod-book-inline-banner__text">
                <h2>Deep Learning in Production Book 📖</h2>
                <h4>
                    Learn how to build, train, deploy, scale and maintain deep
                    learning models. Understand ML infrastructure and MLOps
                    using hands-on examples.
                </h4>
                <Button
                    accent={Accent.primary}
                    type={ButtonType.linkExternal}
                    onClickLink={'https://amzn.to/3oa50Vj'}
                    className="dl-prod-book-inline-banner__text__cta"
                >
                    Learn more
                </Button>
            </div>
        </div>
    );
};

export default InlineDLProdBookBanner;
